#services {
    background-image: url('../img/portfolio/bg-1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    width: 100%;
}

.h1wedo {
    color: #fff;
}

.h4wedo {
    color: #fff;
    margin-bottom: 30px;
}

.col-img {
    width: 30%;
    padding-left: 30px;
}

.col-li {
    width: 70%;
}

.h3wedo {
    text-align: left;
    color: #fff;
    margin-bottom: 30px;
    margin-left: 10px;
    font-size: 30px;
}

.iconwe {
    width: 30%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;

}

.liwe {
    color: #fff;
    text-align: left;
    font-size: 20px;
}

.row-wedo {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

@media (max-width:767px) {
    .iconwe {
        width: 50px;
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
        background-color: yellow;
    }

    .col-li {
        width: 50%;
    }

    .liwe {
        color: #fff;
        text-align: left;
        font-size: 15px;
    }
}

@media (max-width:300px) {
    .iconwe {
        width: 50px;
        background-color: yellow;

        margin: 0 auto;
        margin-top: 40px;
        text-align: center;

    }

    .col-li {
        width: 50%;
    }
}