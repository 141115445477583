.logo {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: forestgreen;
}

.Navlink {
    color: white;
}

.Nav {
    width: 100%;
    height: auto;
}

.navbar-toggler-icon {
    background-color: white;
}

.Navbar {
    background-image: url("../img/bg-header.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 5;

}

@media (max-width: 768px) {
    .Navbar {
        background-color: #C0C0C0;
    }
}

.admin-super {
    background-color: red;
    float: right;
}