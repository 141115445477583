.carousell {
    width: 100%;
    height: 1600px;
    background-image: url("../img/portfolio/bg-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-carousel {
    width: 400px;
    height: 500px;
    margin: 0 auto;
    padding: 20px 0 0;
}

.carousell img {
    margin: 0 auto;
}

.carousel-item {
    width: 100%;
    height: 1000px;
}

.caption1 {
    margin: 10px 0 200px 0;
    height: auto;
    font-size: 100px;

}

.caption2 {
    margin: 40px 0 20px 0;
    font-size: 0.6em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width:700px) {
    .carousell {
        width: 100%;
        height: 2000px;
        background-image: url("../img/portfolio/bg-1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .carousel-item {
        width: 100%;
        height: 1300px;
    }

    .caption1 {
        margin: 0;
        margin: 5px 0 300px 0;
        font-size: 10px;
    }

    .caption2 {
        margin: 0;
        height: auto;
        margin: 40px 0 20px 0;

        font-size: 0.6em;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
}

@media (max-width:300px) {
    .carousel-item {
        padding: 10px;
    }
}