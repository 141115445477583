.input{
    width: 100%;
    height: auto;
    background-color: antiquewhite;
}
.input-content{
    float: left;
    width: 70%;
    height: 700px;
    background-color: chartreuse;
}