#about {
  margin: 30px 0;
  width: 100%;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 50px;
  margin: 0 auto;
}

h4 {
  text-align: center;
  margin: 20px auto;

}

h5 {
  text-align: center;
  margin: 0 auto 30px;
}

hr {
  border: 2px double red;
  width: 500px;
  z-index: 999;
  margin: 20px auto 30px;

}


@media (max-width:767px) {
  hr {
    border: 2px double red;
    width: 60%;
    z-index: 999;
    margin: 20px auto 30px;


  }

  #about {
    padding: 20px;
    width: 100%;
  }

}

@media (max-width:300px) {

  hr {
    border: 2px double red;
    width: 60%;
    z-index: 999;
    margin: 20px auto 30px;


  }

  #about {
    padding: 15px;
    width: 100%;
  }
}