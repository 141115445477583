.media-partner {
    width: 100%;
    color: #fff;
    padding: 30px;
    background: no-repeat center center scroll;
    background-color: #222;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.img-responsive {
    margin: 0 auto;
    width: 210px;
}

.about-carousel {
    background-color: aliceblue;
}

.col-media {
    width: 25%;
    padding: 10px;
    margin: 10px;
}

@media (max-width:767px) {
    .media-partner {
        padding: 20px;
        background-color: #222;
        width: 100%;
        height: auto;

    }

    .col-media img {
        width: 150px;
        padding: 10px;
    }


}

@media (max-width:1200px) {
    .media-partner {
        padding: 20px;
        background-color: #222;
        width: 100%;
        height: auto;

    }

    .col-media img {
        width: 100%;
        padding: 10px;
    }


}

@media (max-width:300px) {
    .media-partner {
        padding: 15px;
        background-color: #222;
        width: 100%;
        height: auto;
    }

    .col-media img {
        width: 100%;
        background-color: aqua;
    }
}