.daftar-pesanan{
    width: 70%;
    padding: 30px;
    float: left;

}
.side{
    width: 30%;
    float: left;
}
.h1{
    text-align: center;
}
.h2admin{
    text-align: center;
    margin-bottom: 30px;
}
.text-area{
    width: 100%;
}